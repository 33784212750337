/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { gsap } from "gsap";

const Container = styled.div`
  width: 100%;
  height: 100svh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;

  video {
    max-width: 100%;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
  }
`;

const VideoContainer = styled.div`
  // padding-top: 56.25%;
  height: 100%;
  position: relative;
  width: 100%;
`;

const VideoPlayer = () => {
  return (
    <Container>
      <VideoContainer>
        <iframe
          allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
          allowFullScreen="true"
          src="https://customer-ql8rstj5wk9oxcua.cloudflarestream.com/fa28b74fe26bdbc5dd1c373401423933/iframe?poster=https%3A%2F%2Fcustomer-ql8rstj5wk9oxcua.cloudflarestream.com%2Ffa28b74fe26bdbc5dd1c373401423933%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D%26height%3D600"
          style={{
            border: "none",
            position: "absolute",
            top: 0,
            left: 0,
            height: "100%",
            width: "100%",
          }}
          title="film"
        />
      </VideoContainer>
    </Container>
  );
};

export default VideoPlayer;
