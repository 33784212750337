import React, { useLayoutEffect, useRef, useState } from "react";
import styled from "styled-components";
import { gsap } from "gsap";

import Logo from "./components/Logo";
import SignIn from "./components/SignIn";
import VideoPlayer from "./components/VideoPlayer";

import "./App.css";

const Container = styled.div``;

const SignInContainer = styled.div``;

const VideoPlayerContainer = styled.div`
  opacity: 0;
`;

function App() {
  const [password, setPassword] = useState("");
  const [signedIn, setSignedIn] = useState("");
  const [passwordFails, setPasswordFails] = useState(0);

  const containerRef = useRef();
  const signInRef = useRef();
  const videoPlayerRef = useRef();
  const signedInTimeline = useRef();

  useLayoutEffect(() => {
    if (signedIn) {
      signedInTimeline.current = gsap
        .timeline({
          defaults: {
            duration: 1.25,
            ease: "power3.out",
          },
        })
        .fromTo(
          signInRef.current,
          {
            opacity: 1,
          },
          {
            opacity: 0,
          }
        )
        .set(signInRef.current, {
          display: "none",
        })
        .fromTo(
          videoPlayerRef.current,
          {
            opacity: 0,
          },
          {
            delay: 3,
            opacity: 1,
          }
        );
    }
  }, [signedIn]);

  return (
    <Container ref={containerRef} onContextMenu={e => e.preventDefault()}>
      <Logo signedIn={signedIn} />
      {!signedIn && (
        <SignInContainer ref={signInRef}>
          <SignIn
            password={password}
            setPassword={setPassword}
            setSignedIn={setSignedIn}
            signedIn={signedIn}
            passwordFails={passwordFails}
            setPasswordFails={setPasswordFails}
          />
        </SignInContainer>
      )}
      {signedIn && (
        <VideoPlayerContainer ref={videoPlayerRef}>
          <VideoPlayer />
        </VideoPlayerContainer>
      )}
    </Container>
  );
}

export default App;
