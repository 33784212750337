import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { gsap } from "gsap";
import { MorphSVGPlugin } from "gsap/MorphSVGPlugin";

gsap.registerPlugin(MorphSVGPlugin);

const Container = styled.div`
  align-items: center;
  display: flex;
  height: 100svh;
  justify-content: center;
  pointer-events: none;
  position: absolute;
  width: 100%;
  z-index: 999;
`;

const LogoSVG = styled.svg`
  enable-background: new 0 0 400 133;
  opacity: 0;
  width: 300px;

  @media only screen and (min-width: 500px) {
    width: 400px;
  }
`;

const PathWhite = styled.path`
  fill: white;
`;

const PolyCover = styled.polygon`
  fill: black;
`;

const Logo = ({ signedIn }) => {
  const svgRef = useRef();
  const logoShapeEndRef = useRef();
  const logoShapeStartRef = useRef();
  const coverShapeEndRef = useRef();
  const coverShapeStartRef = useRef();

  useEffect(() => {
    if (!signedIn) {
      return;
    }

    gsap
      .timeline({
        defaults: {
          duration: 1.25,
          ease: "power3.out",
        },
      })
      .to(svgRef.current, {
        opacity: 1,
      })
      .to(logoShapeStartRef.current, {
        morphSVG: logoShapeEndRef.current,
      })
      .to(
        coverShapeStartRef.current,
        {
          morphSVG: coverShapeEndRef.current,
        },
        "<"
      )
      .to(svgRef.current, {
        delay: 1,
        opacity: 0,
      });
  }, [signedIn]);

  return (
    <Container>
      <LogoSVG
        ref={svgRef}
        version="1.1"
        viewBox="0 0 400 133"
        x="0"
        xmlns="http://www.w3.org/2000/svg"
        xmlSpace="preserve"
        y="0"
      >
        <PathWhite
          id="wordmark"
          d="M72.84,71.91c-15.65,0-26.8,10.19-26.8,26.84c0,16.65,11.66,26.84,26.8,26.84
c15.14,0,26.8-10.42,26.8-26.84S88.49,71.91,72.84,71.91z M72.84,114.01c-8.19,0-12.68-5.57-12.68-15.26
c0-9.69,4.4-15.26,12.68-15.26s12.68,5.71,12.68,15.26C85.52,108.3,81.04,114.01,72.84,114.01z M60.25,63.68H46.24V11.39h35.55
v11.57H60.25v8.79h19.1v11.57h-19.1V63.68z M152.98,63.68V22.97h-13.66V11.39h41.34v11.57h-13.66v40.71H152.98z M186.84,43.29v-31.9
h14.01v31.48c0,6.07,2.41,10,8.92,10c6.51,0,8.9-3.95,8.9-10V11.39h14.01v31.9c0,12.3-5.28,21.15-23.48,21.15
S186.84,54.42,186.84,43.29z M87.81,43.29v-31.9h14.01v31.48c0,6.07,2.41,10,8.92,10s8.9-3.95,8.9-10V11.39h14.01v31.9
c0,12.3-5.28,21.15-23.48,21.15S87.81,54.42,87.81,43.29z M305.85,52.1h23.8v11.57h-37.81V11.39h36.98v11.57h-22.97v7.6h20.53v11.57
h-20.53V52.1z M34.02,74.38c-2.88-1.23-6.37-1.85-10.46-1.85H0v52.28h14.01v-19.24h9.55c6.37,0,11.19-1.42,14.47-4.26
c3.27-2.83,4.91-6.88,4.91-12.13c0-3.72-0.77-6.81-2.3-9.27C39.11,77.46,36.9,75.62,34.02,74.38z M27.64,92.82
c-1,0.79-2.66,1.19-4.99,1.19h-8.64v-9.9h8.64c2.32,0,3.99,0.38,4.99,1.15c1,0.77,1.5,2.03,1.5,3.8
C29.14,90.78,28.64,92.03,27.64,92.82z M152.99,72.54H167v52.28h-14.01V72.54z M172.57,72.54h41.34v11.57h-13.66v40.71h-14.01V84.11
h-13.66V72.54z M219.49,72.54h14.01v52.28h-14.01V72.54z M273.52,72.54h14.21l-17.43,52.28h-15.13l-17.42-52.28h14.22l10.77,35.06
L273.52,72.54z M305.85,113.25h23.8v11.57h-37.81V72.54h36.98v11.57h-22.97v7.6h20.53v11.57h-20.53V113.25z M279.75,40.18
c3.27-2.83,4.91-6.88,4.91-12.13c0-3.72-0.77-6.81-2.3-9.27c-1.53-2.47-3.74-4.31-6.62-5.55c-2.88-1.23-6.37-1.85-10.46-1.85h-24.88
v52.28h14.01V44.44h7.76l11.34,19.24h14.21l-12.65-20.78C276.88,42.21,278.44,41.32,279.75,40.18z M254.42,22.97h9.96
c2.32,0,3.99,0.38,4.99,1.15c1,0.77,1.5,2.03,1.5,3.8c0,1.72-0.5,2.98-1.5,3.76c-1,0.79-2.66,1.19-4.99,1.19h-9.96V22.97z
M147.2,109.41c0,6.41-4.43,16.17-21.36,16.17c-17.81,0-23.03-11.54-23.03-17.57c0,0,8.41,0,13.8,0c1.06,5.69,5.03,6.69,9.72,6.69
c4.46,0,7.49-1.4,7.49-4.04c0-2.83-2.45-4.46-10.49-6.27c-15.96-3.68-19.27-10.5-19.27-16.52c0-9.37,7.89-15.96,19.76-15.96
c16.06,0,21.43,9.36,21.43,16.11c0,0-8.89,0-13.1,0c-1.53-4.98-5.93-5.23-8.47-5.23c-2.34,0-6.86,0.42-6.86,3.69
c0,3.25,5.23,3.99,14.15,6.39C142.08,95.86,147.2,100.39,147.2,109.41z"
        />
        <PathWhite
          id="symbol"
          d="M367.96,63.64h-19.49V0H400L367.96,63.64z M348.48,72.52H400L367.96,133h-19.49V72.52z"
          ref={logoShapeEndRef}
        />
        <PathWhite
          id="rectangles"
          d="M400,63.64H0V0h400V63.64z M0,72.52h400V133H0V72.52z"
          ref={logoShapeStartRef}
        />
        <PolyCover
          id="blackcover-start"
          points="0,63.64 400,63.64 400,72.52 0,72.54 "
          ref={coverShapeStartRef}
        />
        <PolyCover
          id="blackcover-end"
          points="400,63.64 400,63.64 400,72.52 400,72.54 "
          ref={coverShapeEndRef}
        />
      </LogoSVG>
    </Container>
  );
};

export default Logo;
